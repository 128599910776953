<template>
    <div class="views-rules" :class="type === '1' ? 'cities' : ''">
        <div class="rules-content">
<!--                <p><img src="../static/image/rules/rules1.png"/> </p>-->
<!--                <p><img src="../static/image/rules/rules2.png"/> </p>-->
<!--                <p><img src="../static/image/rules/rules3.png"/> </p>-->
<!--                <p><img src="../static/image/rules/rules4.png"/> </p>-->
<!--                <p><img src="../static/image/rules/rules5.png"/> </p>-->
            <div class="rules-text">
                <h2 class="title">新征程·新奇迹</h2>
                <h2 class="title">2022年上海市民文化节红色故事演讲大赛赛事规则</h2>
                <h4 class="sub-title">一、活动概述</h4>
                <p class="text">
                    为迎接党的二十大的胜利召开，结合“党的诞生地”红色文化传承弘扬工程，于7月至9月在全市举办“新征程·新奇迹——2022年上海市民文化节红色故事演讲大赛”，
                    围绕红色历史、发展成就、国防教育、申城战疫等重大主题，充分组织发动全市各社区的广大市民群众，形成“人人都是红色文化传播者”的生动局面，引导人们传承红色基因，坚定理想信念。
                </p>
                <h4 class="sub-title">二、组织机构 </h4>
                <p class="text">指导单位：上海市民文化节指导委员会</p>
                <p class="text">主办单位：中共上海市委宣传部、中共上海市委党史研究室、上海市精神文明建设委员会办公室、中共上海市教育卫生工作委员会、上海市教育委员会、上海警备区政治工作局、上海市文化和旅游局、
                    上海市退役军人事务局、上海市民防办公室、上海市海防委员会办公室、共青团上海市委员会、中共一·二·四大场馆管理委员会、中国少年先锋队上海市工作委员会</p>
                <p class="text">承办单位：上海市群众艺术馆、上海市国防教育协会、东方社区信息苑</p>
                <h4 class="sub-title">三、赛事安排 </h4>
                <p class="text">在全市广泛开展红色故事演讲大赛，通过线上评选、展示，最终评选出百位“红色故事传讲人”和若干单项奖。具体要求如下：</p>
                <ul>
                    <li>
                        <span>参赛要求</span>
                        <p class="text">1.参赛对象：18岁以上，凡生活、工作在上海的市民均可报名参赛。（在校学生和场馆专业讲解员除外）</p>
                        <p class="text">注：场馆专业讲解员请报名参加2022年上海市红色故事讲解员大赛；在校就读的大中小学生请报名参加2022年
                            上海市校园红色故事大赛。</p>
                        <p class="text">2.故事内容：聚焦“新征程·新奇迹”主题，立足上海红色文化资源，讲述与上海有关的故事。包括但不限于以下主题——</p>
                        <p class="text">光荣之城：介绍上海红色场馆或革命遗址</p>
                        <p class="text">红色文物：讲述一件红色文物背后的故事</p>
                        <p class="text">党史故事：讲述奋斗百年路上的党史故事</p>
                        <p class="text">申城战疫：讲述抗疫先进人物和感人故事</p>
                        <p class="text">英模人物：讲述英雄模范人物的光辉事迹</p>
                        <p class="text">发展成就：回顾上海改革开放和发展成就</p>
                        <p class="text">国防教育：介绍国防知识或讲述国防故事</p>
                        <p class="text">要求演讲内容积极向上、健康活泼，体现正能量。</p>
                        <p class="text">3.演讲视角：包括但不限于以下演讲视角——</p>
                        <p class="text">1）喜迎二十大：通过红色故事讲演，回望建党初心，牢记奋斗历程，展望未来道路，在二十大来临之际，用实际行动践行“不忘初心、牢记使命”。</p>
                        <p class="text">2）党的诞生地：围绕“上海是党的诞生地”进行红色故事讲演，助力申城成为改革开放排头兵和创新发展先行者，续写城市新奇迹。</p>
                        <p class="text">3）强国复兴有我：从自我视角出发，在红色故事讲演中，充分把“我”融入这个时代，展现“我”在红色基因传承和强国复兴的浪潮中扮演什么角色、起到什么作用。</p>
                        <p class="text">4.演讲要求：个人独立演讲，时长不超过5分钟。要求精神饱满、口齿清晰、讲述流畅、演讲生动，仪表端庄，服装整洁大方，需脱稿。普通话、方言均可。</p>
                        <p class="text">5.视频要求：本次大赛初评和终评进行线上评选，要求参赛者拍摄演讲视频参赛。</p>
                        <p class="text">视频要求：完整拍摄演讲视频，要求一镜到底，可适当包装（如是方言，建议配字幕）；视频录制器材不限（手机、家用摄像机、专业摄像机均可）；
                            视频文件格式建议为MP4，清晰度1080P，画幅16:9（横屏），单个文件大小不超600M，画面清晰稳定。</p>
                    </li>
                    <li>
                        <span>赛事组织</span>
                        <p class="text">1.报名（7月11日——8月9日）：参赛者请关注“上海市民文化节”微信公众号——选择“市级赛事”——选择“红色故事大赛”报名，
                            也可扫描下方二维码进行线上报名。报名时请填写基本信息、选择所属区和街道，并上传参赛视频。视频经后台审核通过后即可进行展示、分享。</p>
                            <div class="img-box">
                                <img src="../static/image/rules/qrcode.jpg"/>
                            </div>
                            <p class="text">2.各区初评（8月10日——8月16日）：各区对本区线上报名参赛的演讲者视频进行初评，推选出优秀演讲者参加大赛终评。</p>
                            <p class="text">推选名额：每个区10名（浦东新区20名）</p>
                            <p class="text">3.人气点赞（8月17日——8月23日）：平台开放点赞功能，进入终评的参赛者可通过微信转发集赞，将根据点赞数量产生10名“网络人气奖”。</p>
                            <p class="text">4.终评（8月11日——8月20日）：由大赛组委会组织专业评委对进入终评的演讲者视频进行打分、评审。产生百位优秀“红色故事传讲人”。</p>
                    </li>
                </ul>
                <h4 class="sub-title">四、颁奖展示 </h4>
                <p class="text"> 拟于9月17日在上海市群众艺术馆星舞台隆重举行2022年上海市红色故事大赛颁奖展示活动，届时将邀请优秀演讲者代表接受表彰并参与舞台展示活动。</p>
                <h4 class="sub-title">五、奖项设置 </h4>
                <p class="text"> 1.评选出百位优秀“红色故事传讲人”。</p>
                <p class="text"> 2.按点赞数分别产生“网络人气奖”10名。</p>
                <p class="text"> 3.综合各区赛事发动、组织情况、参与数量和推选质量等因素，在16个区中评选若干“优秀组织奖”。</p>
                <h4 class="sub-title">六、主办方提示 </h4>
                <ul>
                    <li>1.本次活动不以任何形式收取任何费用。</li>
                    <li>2.参赛者必须确认拥有所提交作品的版权及著作权,因作品侵权或其他原因所引起的包括(不限于)知识产权、著作权等纠纷，均由参赛者承担相应责任。</li>
                    <li>3.主办方在非营利的前提下，拥有对参赛作品的修改权、发表权、复制权、信息网络传播权和汇编权。所有参赛作品，主办单位有权将其集结出版或用于非商业性的活动宣传等用途，不另付酬金或版权使用费。</li>
                    <li>4.入围者需提供真实、准确的个人身份信息，证书将通过现场或者邮寄的形式予以颁发。</li>
                    <li>5.入围者有义务出席主办方举办的颁奖或者发布仪式。</li>
                    <li>6.大赛结束前，参赛者不可未经主办方同意在其他载体、场合、平台使用、传播参赛作品。</li>
                    <li>7.所有参赛者，即被赛事组委会视为接受并遵守以上条款。</li>
                    <li>8.赛事组委会保留本活动的最终解释权</li>
                </ul>
            </div>
            <div class="bottom-info">
                <p>2022年上海市民文化节</p>
                <p>红色故事大赛组委会</p>
                <p>2022年6月</p>
            </div>
        </div>
        <Back></Back>
    </div>
</template>

<script>
    import getStrParams from "../utils/getStrParams";
    import Back from "../components/Back";
    import WXShare from "../utils/WXShare";

    export default {
        name: "Rules",
        components: {Back},
        data() {
            return {
                type: '1'
            }
        },
        methods: {
        },
        created() {
            this.type = '1'
            WXShare.localShare()  // 微信分享
        }
    }
</script>

<style scoped lang="scss">
    @import "../assets/scss/Index";

    .views-rules {
        background: url("../static/image/ny_bg.png") no-repeat top left #be0b15;
        background-size: contain;
        padding-top: toRem(400);
        &.cities{
            background-image: url("../static/image/ny_bg.png");
        }
        ul {
            list-style: inside;
        }

        .rules-content {
            margin: toRem(12);
            /*background: #F4E7E8;*/
            background: #fff;
            border-top-left-radius: toRem(12);
            border-top-right-radius: toRem(12);
            p{
                padding: toRem(12);
                img{
                    width: 100%;
                }
            }

        }

        .rules-text {
            text-align: left;
            padding: toRem(20);

            h2 {
                font-size: toRem(36);
                font-weight: normal;
                font-style: oblique;
                margin-bottom: toRem(30);
                color: #885858;
            }

            h4 {
                font-weight: normal;
                margin-bottom: toRem(20);
                font-size: toRem(26);
            }

            p {
                text-indent: toRem(50);
                font-size: toRem(22);
                line-height: 1.5;
                margin-bottom: toRem(20);
            }

            ul > li {
                font-size: toRem(22);
                color: #5e5e5e;
                padding: 0 toRem(40);

                span {
                    display: inline-block;
                    margin-bottom: toRem(20);
                }

                p {
                    font-size: toRem(20);
                    margin-bottom: 0;
                }
            }

            .img-box {
                img {
                    width: 100%;
                }

                margin-bottom: toRem(20);
            }

        }

        .bottom-info {
            padding: 0 toRem(20) toRem(40);
            text-align: right;
            font-size: toRem(20);
            color: #885858;
            line-height: 1.5;
        }
    }
</style>



